<template>
  <div>

    <div v-if="status === 'success'" class="container text-center">
      <div v-if="isSendStatusFetching">
        <b-spinner variant="primary"></b-spinner>
      </div>

      <div v-else>
        <div v-if="!sendStatusError">
        <span class="text-green">
          Subscription was paid successfully
          <b-spinner small variant="primary"></b-spinner>
        </span>
        </div>
        <div v-else>
          <span class="text-danger">Paid error.</span>
        </div>
      </div>
    </div>

    <div v-if="status === 'cancel'" class="container text-center">
      <div>
        Payment was cancelled
      </div>
    </div>

  </div>
</template>

<script>
  import {MODULE_NAME as SUBSCRIPTIONS, SEND_PAYMENT_STATUS} from "@/store/modules/subscriptions";
  import {FETCH_PROFILE} from "@/store/modules/profile";

  export default {
    name: 'SubscriptionFinalize',
    data() {
      return {
        status: '',
      }
    },
    computed: {
      sendStatusState() {
        return this.$store.state[SUBSCRIPTIONS].sendPayStatus
      },
      isSendStatusFetching() {
        return this.sendStatusState.loading
      },
      sendStatusData() {
        return this.sendStatusState.data
      },
      sendStatusError() {
        return this.sendStatusState.error
      },

    },
    watch: {
      isSendStatusFetching(newValue, oldValue) {
        console.log(newValue, oldValue)
      }
    },
    async mounted() {
      console.log('SubscriptionFinalize', this.$route.query)
      const {gateway, session_id, status} = this.$route.query

      this.status = status

      if (status === 'success') {
        await this.$store.dispatch(SEND_PAYMENT_STATUS, {
          gateway,
          status,
          data: {
            session_id,
          },
        })
      }

      await this.$store.dispatch(FETCH_PROFILE)

      if (!this.sendStatusError) {
        const self = this
        setTimeout(() => {
          self.goToHome()
        }, 2000)
      }
    },
    methods: {
      paySubscriptionSuccess(type, ) {

      },
      goToHome() {
        this.$router.push({name: 'home'})
      }
    }
  }
</script>
